












import Vue from 'vue'
import {onFido} from '@/helpers'
import OnFidoComponent from '../components/OnFido.vue'
export default Vue.extend({
  name: 'Home',
  data() {
    return {
      appliantId: '',
      loaded: false
    }
  },
  async mounted() : Promise<void> {
    let appliantId = this.$route.query.a;
    try{
      if(!appliantId) throw new Error("Appliant id Missing")
      if(typeof(appliantId) !== 'string') throw new Error("Appliant id is wrong")
      const token = await this.getToken(appliantId)
      if(token === undefined) throw new Error("Missing token")
      const instance = new OnFidoComponent({
        propsData: { token: token, appliantId: appliantId}
      });
      this.loaded = true;
      instance.$mount(".component");
    } catch(e) {
      await this.$router.push("/error");
    }
  },
  methods: {
    getToken: async function(appliantId: string) : Promise<string | undefined> {
      return await onFido.getToken(appliantId);
    }
  }
})
