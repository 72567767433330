




import Vue from 'vue';
import * as onFidoSdk from 'onfido-sdk-ui'
import {onFido} from '@/helpers'

export default Vue.extend({
  name: 'OnFido',
  props: {
    token:{
      type: String,
      required: true
    },
    appliantId: {
      type: String,
      required: true
    }
  },
  async mounted() : Promise<void> {
    await this.initOnfido();
  },
  methods: {
    initOnfido: async function() : Promise<void> {
      const onCompleteOnfido = this.onCompleteOnfido;
      const token = this.token;
      onFidoSdk.init({
        token: token,
        containerId: 'onfido-mount',
        language: 'it_IT',
        steps: [
            'welcome',
            'document',
            'face',
            'complete'
        ],
        onComplete: async function(data: onFidoSdk.SdkResponse) {
          onCompleteOnfido(data);
        }
      })
    },
    
    onCompleteOnfido: async function(data: onFidoSdk.SdkResponse) {
      const verification = await onFido.verification(this.appliantId)
      if(verification){
        await this.$router.push("/success");
      } else {
        await this.$router.push("/error");
      }
    }
  }
});
