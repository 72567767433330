import api from '@/config/api'
import axios from 'axios'

const apiCall = axios.create()
export default class onFido {
  static async getToken(applicantId : string) : Promise<string | undefined> {
    try{
      const tokenReq = await apiCall.get(api.token(applicantId ));
      if(tokenReq){
        return tokenReq.data.token;
      }
      throw new Error('Invalid token request');
    } catch (e) {
      return undefined;
    }
  }

  static async verification(applicantId : string) : Promise<boolean> {
    try{
      const verificationReq = await apiCall.get(api.verification(applicantId));
      if(verificationReq){
        return true
      }
      throw new Error('Invalid verification request');
    } catch (e) {
      return false;
    }
  }
}