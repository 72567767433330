import { isDev } from '@/helpers'
const chainblockApi = isDev() ? 'http://localhost:5001/chainblock-pay/europe-west1/proxy' : 'https://europe-west1-chainblock-pay.cloudfunctions.net/proxy'
const baseApi = `${chainblockApi}/v1/onFido`
const api = {
  token: function (appliantId: string) : string {
    return `${baseApi}/token/${appliantId}`
  },
  verification: function (appliantId: string) : string {
    return `${baseApi}/verification/${appliantId}`
  },
}

export default api